import { fetchAccResults } from '@repository/acc';
import {
  fetchAccApplyGame,
  fetchAccApplyResult,
  fetchAccApplySelfReport,
  fetchAccApplyTip,
  fetchAccApplyVideo,
} from '@repository/acc/apply/useAccaApply';
import {
  fetchAccCompetenceCurationCompany,
  fetchAccCompetenceCurationJob,
  fetchAccCompetenceProfile,
} from '@repository/acc/competence/useAccaCompetence';
import { createStore } from './utils';
import { AccResultRecordVO, AccResultVO } from '@domain/vo/acca/v1/test/result/AccResultVO';
import { AccApplyResultVO } from '@domain/vo/acca/v1/test/result/apply/AccApplyResultVO';
import { AccApplyTipVO } from '@domain/vo/acca/v1/test/result/apply/AccApplyTipVO';
import { AccGameResultVO } from '@domain/vo/acca/v1/test/result/apply/AccGameResultVO';
import { AccSelfReportResultVO } from '@domain/vo/acca/v1/test/result/apply/AccSelfReportResultVO';
import { AccVideoDetailResultVO, AccVideoResultVO } from '@domain/vo/acca/v1/test/result/apply/AccVideoResultVO';
import { AccCompanyCurationVO } from '@domain/vo/acca/v1/test/result/competence/AccCompanyCurationVO';
import { AccJobCurationVO } from '@domain/vo/acca/v1/test/result/competence/AccJobCurationVO';
import { AccProfileResultVO } from '@domain/vo/acca/v1/test/result/competence/AccProfileResultVO';
import { VideoType } from '@domain/constant/acca/test/result/videoType';

export interface AccaTestResultStoreType {
  accResultSn: number;
  accResults: AccResultVO | null;
  accResult: AccResultRecordVO | null;

  setAccResultBySn: (accResultSn: number) => void;
  fetchAccResults: () => Promise<void>;

  /** 역량검사 응시 분석 결과 **/
  accApplyResult: AccApplyResultVO | null;
  accSelfReportResult: AccSelfReportResultVO | null;
  accVideoResult: AccVideoResultVO | null;
  accVideoDetailMap: Map<VideoType, AccVideoDetailResultVO[]> | null;
  accGameResult: AccGameResultVO | null;
  accApplyTip: AccApplyTipVO | null;

  fetchAccApplyResult: () => Promise<void>;
  fetchAccApplySelfReport: () => Promise<void>;
  fetchAccApplyVideo: () => Promise<void>;
  fetchAccApplyGame: () => Promise<void>;
  fetchAccApplyTip: () => Promise<void>;

  /** 역량검사 역량 분석 결과 **/
  accProfileResult: AccProfileResultVO | null;
  accCompanyCuration: AccCompanyCurationVO | null;
  accJobCuration: AccJobCurationVO | null;

  fetchAccCompetenceProfile: () => Promise<void>;
  fetchAccCompetenceCurationCompany: () => Promise<void>;
  fetchAccCompetenceCurationJob: () => Promise<void>;
}

export const useAccaTestResultStore = createStore<AccaTestResultStoreType>((set, get) => ({
  accResultSn: -1,
  accResults: null,
  accResult: null,

  accApplyResult: null,
  accSelfReportResult: null,
  accVideoResult: null,
  accVideoDetailMap: null,
  accGameResult: null,
  accApplyTip: null,

  accProfileResult: null,
  accCompanyCuration: null,
  accJobCuration: null,

  setAccResultBySn: (accResultSn: number) => {
    const accResults = get().accResults;
    const accResult =
      accResults?.resultRecords?.find((resultRecord) => {
        return resultRecord.sn === accResultSn;
      }) || null;
    set((state) => {
      state.accResultSn = accResultSn;
      state.accResult = accResult;
    });
  },
  fetchAccResults: async () => {
    const accResults = await fetchAccResults();
    set((state) => {
      state.accResults = accResults;
    });
  },
  fetchAccApplyResult: async () => {
    const accResultSn = get().accResultSn;
    const accApplyResult = await fetchAccApplyResult(accResultSn);
    set((state) => {
      state.accApplyResult = accApplyResult;
    });
  },
  fetchAccApplySelfReport: async () => {
    const accResultSn = get().accResultSn;
    const accSelfReportResult = await fetchAccApplySelfReport(accResultSn);
    set((state) => {
      state.accSelfReportResult = accSelfReportResult;
    });
  },
  fetchAccApplyVideo: async () => {
    const accResultSn = get().accResultSn;
    const accVideoResult = await fetchAccApplyVideo(accResultSn);
    const accVideoDetailMap = new Map<VideoType, AccVideoDetailResultVO[]>();
    accVideoResult?.details?.forEach((detail) => {
      const { type } = detail;
      accVideoDetailMap?.set(type, [...(accVideoDetailMap?.get(type) || []), detail]);
    });
    set((state) => {
      state.accVideoResult = accVideoResult;
      state.accVideoDetailMap = accVideoDetailMap;
    });
  },
  fetchAccApplyGame: async () => {
    const accResultSn = get().accResultSn;
    const accGameResult = await fetchAccApplyGame(accResultSn);
    set((state) => {
      state.accGameResult = accGameResult;
    });
  },
  fetchAccApplyTip: async () => {
    const accResultSn = get().accResultSn;
    const accApplyTip = await fetchAccApplyTip(accResultSn);
    set((state) => {
      state.accApplyTip = accApplyTip;
    });
  },
  fetchAccCompetenceProfile: async () => {
    const accResultSn = get().accResultSn;
    const accProfileResult = await fetchAccCompetenceProfile(accResultSn);
    set((state) => {
      state.accProfileResult = accProfileResult;
    });
  },
  fetchAccCompetenceCurationCompany: async () => {
    const accResultSn = get().accResultSn;
    const accCompanyCuration = await fetchAccCompetenceCurationCompany(accResultSn);
    set((state) => {
      state.accCompanyCuration = accCompanyCuration;
    });
  },
  fetchAccCompetenceCurationJob: async () => {
    const accResultSn = get().accResultSn;
    const accJobCuration = await fetchAccCompetenceCurationJob(accResultSn);
    set((state) => {
      state.accJobCuration = accJobCuration;
    });
  },
}));
