import { UseQueryOptions, useQuery } from 'react-query';
import { RemoteAccCompetenceRepo } from './AccCompetenceRepo';

const remoteRepo = new RemoteAccCompetenceRepo();

export const accCompetenceKeys = ['acca', 'competence'];

export const fetchAccCompetenceProfile = async (sn: number) => {
  const { data } = await remoteRepo.fetchAccCompetenceProfile(sn);
  return data;
};

export function useAccCompetenceProfile(sn: number, options?: UseQueryOptions) {
  return useQuery([...accCompetenceKeys, sn], () => fetchAccCompetenceProfile(sn), options as any);
}

export const fetchAccCompetenceCurationCompany = async (sn: number) => {
  const { data } = await remoteRepo.fetchAccCompetenceCurationCompany(sn);
  return data;
};

export function useAccCompetenceCurationCompany(sn: number, options?: UseQueryOptions) {
  return useQuery([...accCompetenceKeys, sn], () => fetchAccCompetenceCurationCompany(sn), options as any);
}

export const fetchAccCompetenceCurationJob = async (sn: number) => {
  const { data } = await remoteRepo.fetchAccCompetenceCurationJob(sn);
  return data;
};

export function useAccCompetenceCurationJob(sn: number, options?: UseQueryOptions) {
  return useQuery([...accCompetenceKeys, sn], () => fetchAccCompetenceCurationJob(sn), options as any);
}
