import { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import { AccProfileResultRs } from '@domain/rs/acca/v1/test/result/competence/AccProfileResultRs';
import { AccCompanyCurationRs } from '@domain/rs/acca/v1/test/result/competence/AccCompanyCurationRs';
import { AccJobCurationRs } from '@domain/rs/acca/v1/test/result/competence/AccJobCurationRs';

const accCompetencePrefix = '/acc/competence';

/** 잡다에서 요청하는 역량검사 결과표 **/
export default interface AccCompetenceRepo {
  /** 역량검사 역량 분석 결과 **/

  // 역량 유형, 역량 프로파일, 면접 예상 질문 가이드
  fetchAccCompetenceProfile(sn: number): Promise<AxiosResponse<AccProfileResultRs>>;

  // 기업 문화 추천
  fetchAccCompetenceCurationCompany(sn: number): Promise<AxiosResponse<AccCompanyCurationRs>>;

  // 직군/직무 추천
  fetchAccCompetenceCurationJob(sn: number): Promise<AxiosResponse<AccJobCurationRs>>;
}

export class RemoteAccCompetenceRepo implements AccCompetenceRepo {
  /** 역량검사 역량 분석 결과 **/

  fetchAccCompetenceProfile(sn: number): Promise<AxiosResponse<AccProfileResultRs>> {
    return axios.get(`${accCompetencePrefix}/profile`, { params: { sn } });
  }

  fetchAccCompetenceCurationCompany(sn: number): Promise<AxiosResponse<AccCompanyCurationRs>> {
    return axios.get(`${accCompetencePrefix}/curation/company`, { params: { sn } });
  }

  fetchAccCompetenceCurationJob(sn: number): Promise<AxiosResponse<AccJobCurationRs>> {
    return axios.get(`${accCompetencePrefix}/curation/job`, { params: { sn } });
  }
}
