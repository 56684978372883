import { UseQueryOptions, useQuery } from 'react-query';
import { RemoteAccApplyRepo } from './AccApplyRepo';

const remoteAccRepo = new RemoteAccApplyRepo();

export const accApplyKeys = ['acca', 'apply'];

export const fetchAccApplyResult = async (sn: number) => {
  const { data } = await remoteAccRepo.fetchAccApplyResult(sn);
  return data;
};

export function useAccApplyResult(sn: number, options?: UseQueryOptions) {
  return useQuery([...accApplyKeys, sn], () => fetchAccApplyResult(sn), options as any);
}

export const fetchAccApplySelfReport = async (sn: number) => {
  const { data } = await remoteAccRepo.fetchAccApplySelfReport(sn);
  return data;
};

export function useAccApplySelfReport(sn: number, options?: UseQueryOptions) {
  return useQuery([...accApplyKeys, sn], () => fetchAccApplySelfReport(sn), options as any);
}

export const fetchAccApplyVideo = async (sn: number) => {
  const { data } = await remoteAccRepo.fetchAccApplyVideo(sn);
  return data;
};

export function useAccApplyVideo(sn: number, options?: UseQueryOptions) {
  return useQuery([...accApplyKeys, sn], () => fetchAccApplyVideo(sn), options as any);
}

export const fetchAccApplyGame = async (sn: number) => {
  const { data } = await remoteAccRepo.fetchAccApplyGame(sn);
  return data;
};

export function useAccApplyGame(sn: number, options?: UseQueryOptions) {
  return useQuery([...accApplyKeys, sn], () => fetchAccApplyGame(sn), options as any);
}

export const fetchAccApplyTip = async (sn: number) => {
  const { data } = await remoteAccRepo.fetchAccApplyTip(sn);
  return data;
};

export function useAccApplyTip(sn: number, options?: UseQueryOptions) {
  return useQuery([...accApplyKeys, sn], () => fetchAccApplyTip(sn), options as any);
}
